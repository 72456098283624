<template>
  <div>
    <base-header class="pb-6" type="primary">
      <breadcrumb-header :items="breadcrumb"></breadcrumb-header>
      <div class="row">
        <div class="col-xl-9 col-md-6">
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div
        class="alert alert-wrapper"
        :class="`alert-${alertStatus}`"
        ref="alert"
        v-show="formSent"
      >
        {{ alertMessage }}
      </div>

      <div class="row">
        <div class="col-lg-6 col-xl-5 order-xl-1">
          <product-photos-manage v-model="cPhotos" @added="handleAdded" />
          <product-name-form :form-data="itemData"
                             :category-options="selectOptions.categories"
                             :line-options="selectOptions.lines"
                             :tag-options="selectOptions.tags"
                             :recommendation-options="selectOptions.recommendations"
          />
          <product-description-form :form-data="itemData"/>
          <product-seo-form :form-data="itemData" />
        </div>

        <div class="col-lg-6 col-xl-7 order-xl-1">
          <product-price-form :form-data="itemData"/>
          <product-property-form :form-data="itemData"
                                 :property-options="selectOptions.properties"
                                 @added="handleAdded"
          />
        </div>

      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-primary" @click="saveProduct" :disabled="submitDisabled">Zapisz</button>
        <button class="btn btn-primary" @click="fixProduct" :disabled="submitDisabled">Napraw produkt</button>
      </div>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers, mapMutations} from 'vuex'

import ProductNameForm from "@/views/Store/Product/ProductNameForm";
import ProductPriceForm from "@/views/Store/Product/ProductPriceForm";
import ProductPropertyForm from "@/views/Store/Product/ProductPropertyForm";
import BreadcrumbHeader from "@/views/Store/Layout/BreadcrumbHeader";
import ProductDescriptionForm from "@/views/Store/Product/ProductDescriptionForm";
import ProductSeoForm from "@/views/Store/Product/ProductSeoForm";
import ProductPhotosManage from "@/components/TailwindComponents/ProductPhotosManage";

const {mapState } = createNamespacedHelpers('ProductViewModule');

export default {
  name: 'ProductView',
  components: {
    ProductPhotosManage,
    BreadcrumbHeader,
    ProductPriceForm,
    ProductPropertyForm,
    ProductNameForm,
    ProductDescriptionForm,
    ProductSeoForm
  },
  data() {
    return {
      submitDisabled: false,
      formSent: false,
      alertMessage: '',
      alertStatus: ''
    }
  },
  computed: {
    ...mapState({
      itemData: state => state.item,
      selectOptions: state => state.options,
    }),
    cBaseGrossPrice() {
      try {
        return this.parsePrice(this.itemData.baseGrossPrice)
      } catch (e) {
        return 0
      }
    },
    cTaxRate() {
      try {
        return '' + (this.itemData.taxRate * 100)
      } catch (e) {
        return 0
      }
    },
    cBaseGrossPriceVuexBinding() {
      try {
        return {
          baseGrossPrice: this.cBaseGrossPrice
        }
      } catch (e) {
        return {}
      }
    },
    cBaseNetPriceVuexBinding() {
      try {
        return {
          baseNetPrice: this.parsePrice(this.itemData.baseNetPrice)
        }
      } catch (e) {
        return {}
      }
    },
    cTaxRateVuexBinding() {
      try {
        return {
          taxRate: +this.cTaxRate
        }
      } catch (e) {
        return {}
      }
    },
    cPhotos: {
      get() {
        const image = this.itemData.image
        const gallery = this.itemData.gallery
        if ( !image && !gallery.length ) {
          return []
        }
        if ( !image && gallery.length ) {
          return gallery.map( image => ({
            order: image.order,
            media: 'picture',
            thumbUrl: image.thumbUrl,
            url: image.url
          }))
        }
        if ( image && !gallery.length ) {
          return [
            {
              order: 0,
              media: 'picture',
              thumbUrl: image.thumbUrl,
              url: image.url
            }
          ]
        }
        try {
          return [
            {
              order: 0,
              media: 'picture',
              thumbUrl: image.thumbUrl,
              url: image.url
            },
            ...gallery.map( image => ({
              order: image.order,
              media: 'picture',
              thumbUrl: image.thumbUrl,
              url: image.url
            }))
          ]
        } catch (e) {
          return []
        }
      },
      set(items) {
        console.log('setItems', items)
        const image = {
          ...items[0]
        }
        this.setImages({
          image,
          gallery: [...items.filter( (item, index) => index )]
        })
      }
    },

    breadcrumb() {
      return [
        {title: 'Sklep', link: {name: 'store'}},
        {title: 'Produkty', link: {name: 'product-list'}},
        {title: `Dodanie nowego produktu`}
      ];
    },
  },
  methods: {
    ...mapMutations('ProductViewModule', ['setImages']),
    handleAdded(event){
      const mapped = event.map( (image, index) => ({
        media: 'picture',
        order: this.itemData.gallery.length + index,
        url: image,
        thumbUrl: image
      }))
      if ( !this.itemData?.image ?? '' ) {
        const image = mapped[0]
        const gallery = mapped.length > 1 ? mapped.slice(-mapped.length + 1) : []
        this.setImages({
          image,
          gallery
        })
      }
      else {
        const gallery = [
          ...this.itemData.gallery,
          ...mapped
        ]
        console.log(gallery)
        this.setImages({
          gallery
        })
      }
    },
    parsePrice(price) {
      return '' + Math.ceil(parseFloat(price) * 100)
    },
    updateNetPrice() {
      this.itemData.baseNetPrice = parseFloat((+this.cBaseGrossPrice) / +(`1.${this.itemData.taxRate}`) / 100).toFixed(2)
    },
    async saveProduct() {
      try {
        this.submitDisabled = true;
        this.formSent = false;
        this.alertStatus = '';
        const { ean, active, archived, available, name, seo, description, category, line, discount, tags, properties, recommendations, producer } = this.itemData
        await this.$store.dispatch('ProductViewModule/postProduct', {
          token: this.$store.state.Auth.token,
          id: this.$route.params.id,
          data: {
            ean,
            active,
            archived,
            available,
            name,
            description,
            producer: `/api/v1/rest/producers/891919db-1548-4277-801e-e43a6228bd8e`,
            ...this.cBaseGrossPriceVuexBinding,
            ...this.cBaseNetPriceVuexBinding,
            ...this.cTaxRateVuexBinding,
            image: {
              ...this.itemData.image,
              url: this.itemData.image.url.split('https://cdn.clochee.com/prod/').join(''),
              thumbUrl: this.itemData.image.thumbUrl.split('https://cdn.clochee.com/prod/').join('')
            },
            gallery: this.itemData.gallery.map( item => ({ ...item, url: item.url.split('https://cdn.clochee.com/prod/').join(''), thumbUrl: item.thumbUrl.split('https://cdn.clochee.com/prod/').join('') })),
            discount: +(discount || 0),
            tags: tags.map( tag =>  `/api/v1/rest/product-tags/${tag}`),
            category: `/api/v1/rest/product-categories/${category}`,
            recommendations: (recommendations ?? []).map( product => `/api/v1/rest/products/${product}`),
            line: `/api/v1/rest/product-lines/${line}`,
            properties,
            seo
          }
        })
        this.formSent = true;
        this.alertStatus = 'success';
        this.alertMessage = 'Produkt został dodany pomyślnie'
        this.submitDisabled = false;
      } catch (e) {
        console.error(e)
        this.formSent = true;
        this.alertStatus = 'danger'
        this.alertMessage = 'Coś poszło nie tak'
      }
      setTimeout(() => {
        this.$refs.alert.scrollIntoView({ behavior: 'smooth' })
      }, 100)

    },
    async repairMissingImages() {
      await this.$store.dispatch('ProductViewModule/updateProduct', {
        token: this.$store.state.Auth.token,
        id: this.$route.params.id,
        data: {
          image: {
            "media": "picture",
            "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml.webp",
            "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml_th.webp",
            "iconUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml_ico.jpg",
            "host": "https://cdn.clochee.com/prod"
          },
          gallery: [
            {
              "order": 1,
              "media": "picture",
              "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b2_2.webp",
              "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b2_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            },
            {
              "order": 2,
              "media": "picture",
              "url": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b_2.webp",
              "thumbUrl": "wonder-tan-pianka-brazujaca-do-ciala-i-twarzy-5903900380768-150ml-b_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            },
            {
              "order": 3,
              "media": "picture",
              "url": "wonder-tan-brazujaca-pianka-do-ciala-i-twarzy_2.webp",
              "thumbUrl": "wonder-tan-brazujaca-pianka-do-ciala-i-twarzy_2_th.webp",
              "host": "https://cdn.clochee.com/prod"
            }
          ]
        }
      })
    },
    async fixProduct() {
      await this.$store.dispatch('ProductViewModule/fixProduct', {
        token: this.$store.state.Auth.token
      })
    },
  },
  beforeMount() {
    this.$store.dispatch('ProductViewModule/addItem', {
      token: this.$store.state.Auth.token
    })
  },
  watch: {
    cBaseGrossPrice() {
      this.updateNetPrice();
    },
    cTaxRate() {
      this.updateNetPrice();
    }
  }
};
</script>
<style>
  .alert-wrapper {
    scroll-margin-top: 20px;
  }
</style>
